<template>
  <sdCards>
    <template v-slot:title>
      <div class="setting-card-title">
        <sdHeading as="h4">PDF Settings</sdHeading>
        <span>Update your PDF Header, Footer</span> <br />
      </div>
    </template>
    <a-row>
      <a-col :xs="24">
        <BasicFormWrapper>
          <a-form
            :model="formState"
            @finish="handleFinish"
            @finishFailed="handleFinishFailed"
            layout="vertical"
          >
            <div class="account-form-top">
              <a-row type="flex" justify="center">
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <div class="account-form">
                    <a-form-item name="basic-textarea" label="PDF header">
                      <a-textarea placeholder="Basic usage" :rows="2" />
                    </a-form-item>

                    <a-form-item name="basic-textarea" label="PDF Footer">
                      <a-textarea placeholder="Basic usage" :rows="2" />
                    </a-form-item>
                  </div>
                </a-col>
              </a-row>
            </div>

            <div class="account-form-bottom">
              <a-row type="flex" justify="center">
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <div class="account-action">
                    <div class="setting-form-actions">
                      <sdButton size="default" htmlType="submit" type="primary">
                        Save Change
                      </sdButton>
                      &nbsp; &nbsp;
                      <sdButton
                        size="default"
                        @click="handleCancel"
                        type="light"
                      >
                        Cancel
                      </sdButton>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-form>
        </BasicFormWrapper>
      </a-col>
    </a-row>
  </sdCards>
</template>

<script>
import { BasicFormWrapper } from "../../styled";
import { reactive, defineComponent } from "vue";

const PDFSettings = defineComponent({
  name: "PDFSettings",
  components: { BasicFormWrapper },
  data() {
    const name = "clayton";
    const formState = reactive({
      username: name,
      email: "contact@example.com",
    });

    const handleFinish = (values) => {
      this.values = { ...values };
      console.log(values, formState);
    };

    const handleFinishFailed = (errors) => {
      console.log(errors);
    };
    return {
      name,
      values: null,
      formState,
      handleFinish,
      handleFinishFailed,
      // form: this.$form.createForm(this, { name: "account" }),
    };
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.values = { ...values, tags: this.tags };
          console.log(values);
        }
      });
    },
    handleCancel(e) {
      e.preventDefault();
    },
    handleChange(e) {
      this.name = e.target.value;
    },
  },
});

export default PDFSettings;
</script>
